.language-switcher button {
    margin: 5px;
    padding: 5px;
    border: solid 1px #ed7650;
    text-decoration: none;
    color: #ed7650; /* Use the specified color for the links */
    transition: color 0.1s ease; /* Add a smooth transition effect for color changes */
    background-color: white;
    cursor: pointer;
}

.language-switcher button:hover {
    background-color: #ed7650;
    color: white;
  }

.language-switcher {
    background-color: #ffece6;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: center;  
    flex-wrap: wrap;
    padding: 10px;
}

.language-switcher li {
    width: fit-content;
}