/* Navigation.css */
nav {
  padding: 10px;
}

.nav ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nav li {
  display: flex;
  width: 100%;
}

