body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Borna";
  src: local("BornaMedium"),
   url("./fonts/Borna-Medium.otf") format("truetype");
  font-weight: medium;
 }

 @font-face {
  font-family: "Borna";
  src: local("BornaRegular"),
       url("./fonts/Borna-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "TTHovesDemiBold";
  src: local("TTHovesDemiBold"),
       url("./fonts/TTHoves-DemiBold.ttf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "TTHovesItalic";
  src: local("TTHovesItalic"),
       url("./fonts/TTHoves-Italic.ttf") format("opentype");
  font-style: italic;
}

@font-face {
  font-family: "TTHovesMedium";
  src: local("TTHovesMedium"),
       url("./fonts/TTHoves-Medium.ttf") format("opentype");
  font-weight: medium;
}

@font-face {
  font-family: "TTHovesMediumItalic";
  src: local("TTHovesMediumItalic"),
       url("./fonts/TTHoves-MediumItalic.ttf") format("opentype");
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: "TTHovesRegular";
  src: local("TTHovesRegular"),
       url("./fonts/TTHoves-Regular.ttf") format("opentype");
  font-weight: normal;
}
