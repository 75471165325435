.App {
  box-sizing: content-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
a:link {
  width: 100%;
  margin: 5px;
  padding: 5px;
  border: solid 1px #ed7650;
  text-decoration: none;
  color: #ed7650 !important;
  transition: color 0.3s ease;
}


a:visited {
  color: #ed7650 !important;
}

a:hover {
  border: solid 2px #ed7650;
  padding: 4px;
  font-weight: bold;
}

.go-home {
  margin: 10px;
  padding: 5px;
  margin-top: 15px !important;
  width: calc(100% - 40px) !important;
}

.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.headerGraphic h1, .headerGraphic span {
  font-family: "Borna", sans-serif !important;
}

h1 {
  color: #ed7650;
  margin: 20px;
  font-family: "Borna", sans-serif;
  font-weight: medium;
  font-size: 40px;
}

h2 {
  color: #747474;
  margin: 10px;
  font-family: "Borna", sans-serif;
  font-weight: medium;
  font-size: 30px;
  margin-top: 2rem;
}

h3 {
  color: #1d1d1d;
  margin: 5px;
  font-family: "Borna", sans-serif;
}

p, span, ol {
  font-family: "TTHovesRegular", sans-serif;
  font-size: 1rem;
}

ol {
  list-style-type: none;
  text-align: center;
  counter-reset: my-counter;
  padding: 0; 
  margin: 0;
  padding-top: 0.5rem;
}

ol li {
  counter-increment: my-counter;
  margin-bottom: 10px;
}

ol li::before {
  content: counter(my-counter) ".";
  margin-right: 5px; 
}


p {
  color: #1d1d1d;
  padding: 0 20px;
  max-width: 800px;
}

.intro {
  color: #ed7650;
  margin: 20px 0;
  font-family: "Borna", sans-serif;
  font-weight: medium;
  font-size: 1.2rem;
}

footer {
  display: flex;
  flex-direction: column;
  background-color: #ffece6;
  justify-content: center;
  align-items: center;
}

.App .simple-link {
  border: none;
  width: fit-content;
  background-color: transparent;
  text-decoration: none;
}

.App .simple-link:hover {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-weight: normal;
  padding: 5px;
}


.likeButton {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 1rem;
  text-decoration: underline;
}

.likeButton span  {
  color: black;
  padding: 0;
}


.likeButton:hover, .likeButton:hover span {
  color: #ed7650;
} 

.heart {
  width: 3rem;
  height: 3rem;
}

.likeButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.likeButtonWrapper .likes {
  color: #ed7650;
  font-size: 2rem;
}

.likeButtonWrapper p {
  font-size: 1rem;
  font-family: "Borna";
  font-weight: medium;
  padding: 0;
  margin: 0;
  color: black;
}

a,
button {
  font-family: "Borna", sans-serif;
  font-weight: medium;
}

.desc,
.activities {
  display: flex;
  margin: 1rem;
  flex-direction: column;
}

.activities span {
  margin: 0.5em;
  max-width: 500px;
}

.descHeader,
.activitiesHeader {
  font-size: 1.2rem;
  font-family: "Borna";
  font-weight: medium;
  padding: 0;
  margin: 0;
  color: #ed7650;
}

.activitiesHeader {
  font-size: 1.5rem;
}

.HeaderGraphic {
  background-color: #ed7650;
}

.headerGraphic {
  background-color: #ed7650;
  padding: 2em;
}

.headerGraphic>p {
  border: 1px black solid;
  font-size: 1.5em;
  border-radius: 1.5em;
  color: black;
  width: fit-content;
  padding: 0.2em 0.6em;
}

.headerGraphic>h1 {
  color: black;
  font-size: 3em;
  margin-bottom: 0;
}

.headerGraphic span {
  font-size: 2em;
  color: white;
}

.videoWrapper {
  background-color: #ffece6;
  width: calc(100% - 4rem);
  padding: 2em;
}

.location {
  width: 100%;
  max-width: 650px;
}


.Renderings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px;
}

.Renderings img {
  max-width: calc(50% - 20px);
  max-height: 100%;
  object-fit: contain;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
}

.fixedButtonContainer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 3;
}

.fixedButtonContainer {
  position: fixed;
  bottom: 10px;
  right: 32px;
  z-index: 3;
}

.fixedButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 10px;
  background-color: #ffece6;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

.fixedButton:hover {
  font-weight: normal;
  width: 100%;
  margin: 5px;
  padding: 4px;
  border: solid 2px #ed7650;
  text-decoration: none;
  color: #ed7650 !important;
  transition: color 0.3s ease;
}

.overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffece6;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content h2 {
  padding-top: 0;
  margin-top: 0;
}

.overlay-content {
  text-align: center;
  color: white;
}

footer p {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {

  .Renderings img {
    padding: 0;
    margin: 10px;
    max-width: calc(100% - 20px);

  }

  .headerGraphic span {
    font-size: 1.8em;
  }
}


@media screen and (max-width: 600px) {


  .headerGraphic {
    padding: 1em;
  }

  .headerGraphic>h1 {
    font-size: 2em;
  }

  .headerGraphic span {
    font-size: 1.4em;
  }

  .headerGraphic>p {
    font-size: 1em;
    margin-bottom: 2.5em;
  }

  video {
    width: 100%;
  }

  .videoWrapper {
    background-color: #ffece6;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

html.rtl-text,
body.rtl-text,
.rtl-text *,
.rtl-text
.activitiesHeader, 
.rtl-text .headerGraphic p {
  font-family: "Vazirmatn", "Borna", sans-serif;
}